import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { FurnitureSearch } from "../../App.js";
import { getReq } from "../../configs/ApiUrl.js";
import ProductCard from "../../screens/ProductCard.js";
import { LoadingSpinner } from "./util/LoadingSpinner.js";
import { HiMagnifyingGlass } from "react-icons/hi2";
import PageHeader from "../PageHeader.js";
import PageFooter from "../PageFooter.js";

function SearchResults() {
  const { query } = useParams(); // Get the search query from URL
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useContext(FurnitureSearch);

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const response = await getReq(`/product/search/${query}`);
        if (response?.products) {
          setSearchResults(response.products);
        } else {
          setSearchResults([]); // Explicitly setting an empty array if no products are found
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
        setSearchResults([]); // Handle error by showing no products found
      } finally {
        setLoading(false);
      }
    };

    fetchSearchResults();
  }, [query]);

  return (
    <div className='flex flex-col items-center mt-16 bg-white'>
      <PageHeader className='mb-10' />
      <div className='w-full'>
        <form
          className='flex relative bg-white rounded-xl mb-6 w-full'
          onSubmit={(e) => {
            e.preventDefault();
            const newQuery = e.target.search.value;
            setSearchTerm({ ...searchTerm, query: newQuery });
            navigate(`/search-results/${newQuery}`);
          }}>
          <input
            className='border-b-[2px] border-0 border-stone-400 w-full h-14 pl-4 focus:border-cromboOrange focus:ring-0'
            placeholder='Search for products'
            name='search'
            defaultValue={query}
          />
          <button
            type='submit'
            className='h-14 w-14 bg-cromboOrange flex items-center justify-center'>
            <HiMagnifyingGlass
              color='white'
              className='w-7 h-8'
            />
          </button>
        </form>

        <div className="px-4 mb-8 text-lg">Showing Results for "{query}"</div>

        {loading ? (
          <div className='w-full flex justify-center items-center'>
            <LoadingSpinner />
          </div>
        ) : searchResults.length > 0 ? (
          <div className='grid lg:grid-cols-6 grid-cols-2 gap-4'>
            {searchResults.map((product) => (
              <ProductCard
                key={product._id}
                id={product._id}
                name={product.title}
                price={product.price}
                url={product.mainImage || product.imageNames[0]}
                moving={product.deliveryPrice}
                condition={product.condition}
                verified={product.isQualityVerified}
                onProductSelect={() =>
                  navigate(`/catalogue-product/${product._id}`)
                }
              />
            ))}
          </div>
        ) : (
          <div className='w-full text-center text-gray-500'>
            <p>No products found for "{query}"</p>
            <p>
              Try adjusting your search or go back to{" "}
              <Link
                to='/shopping-home'
                className='text-cromboOrange'>
                Shopping Home
              </Link>
              .
            </p>
          </div>
        )}
      </div>
      <div className='block md:hidden'>
        <PageFooter />
      </div>
    </div>
  );
}

export default SearchResults;
